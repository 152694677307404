/* Include Tailwind CSS Base Styles */
@tailwind base;

/* Include Tailwind CSS Component Styles */
@tailwind components;

/* Include Tailwind CSS Utility Styles */
@tailwind utilities;

/* Reset and Global Styles */
:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-color: #f9f9f9;
  --text-color: #333;
  --heading-font: 'Roboto', sans-serif;
  --body-font: 'Arial', sans-serif;
  --border-radius: 4px;
  --spacing-unit: 1rem;
  --transition-duration: 0.3s;
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  font-size: 16px;
}

/* Global box-sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
  margin: 0 0 0.5em;
  color: var(--text-color);
}

p {
  margin: 0 0 var(--spacing-unit);
  color: var(--text-color);
}

small {
  font-size: 0.875rem;
  color: var(--secondary-color);
}

/* Links */
a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color var(--transition-duration);
}

a:hover, a:focus {
  text-decoration: underline;
  color: darken(var(--primary-color), 10%);
}

/* Buttons */
button {
  font-family: inherit;
  font-size: 1rem;
  padding: 0.6em 1.2em;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

button:hover {
  background-color: darken(var(--primary-color), 10%);
  transform: scale(1.05);
}

button:disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}

/* Primary Action Button */
button.primary {
  background-color: #28a745;
}

button.primary:hover {
  background-color: #218838;
}

/* Secondary Action Button */
button.secondary {
  background-color: var(--secondary-color);
}

button.secondary:hover {
  background-color: darken(var(--secondary-color), 10%);
}

/* Form Inputs */
input, select, textarea {
  width: 100%;
  padding: 0.8em;
  margin: 0.5em 0;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  transition: border-color var(--transition-duration), box-shadow var(--transition-duration);
}

input:focus, select:focus, textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Form Labels */
label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
}

/* Lists */
ul, ol {
  margin: var(--spacing-unit) 0;
  padding-left: 1.5em;
}

li {
  margin-bottom: 0.5em;
}

/* Feedback Messages */
.alert {
  padding: 1em;
  border-radius: var(--border-radius);
  margin: 1em 0;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Layout Elements */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-unit);
}

.section {
  padding: var(--spacing-unit) 0;
}

.card {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-unit);
  margin: var(--spacing-unit) 0;
  transition: box-shadow var(--transition-duration);
}

.card:hover, .card:focus {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Dashboard Layout */
.dashboard-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1em;
  margin: 2em;
  padding: 1em;
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.dashboard-sidebar {
  background-color: #f1f1f1;
  padding: var(--spacing-unit);
  border-radius: var(--border-radius);
}

.dashboard-sidebar a {
  display: block;
  margin: 0.5em 0;
  color: var(--primary-color);
}

.dashboard-sidebar a:hover {
  color: darken(var(--primary-color), 10%);
  font-weight: bold;
}

.dashboard-main {
  padding: var(--spacing-unit);
}

/* Chart Styles */
.chart-container {
  margin-top: var(--spacing-unit);
  padding: var(--spacing-unit);
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.chart-title {
  font-size: 1.2rem;
  margin-bottom: var(--spacing-unit);
  text-align: center;
  color: var(--text-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  body {
    font-size: 0.9rem;
  }

  .dashboard-container {
    grid-template-columns: 1fr;
  }

  button {
    padding: 0.5em 1em;
  }

  input, select, textarea {
    padding: 0.7em;
  }
}
